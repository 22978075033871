import {
  NewsFragmentFragment,
  NewsTeaserFragmentFragment,
  ParagraphColumns,
  ParagraphColumnsFragmentFragment,
} from '../../../generated/types';
import React from 'react';
import { TeaserProps } from '../../../types/global-types';
import { Box } from '@chakra-ui/react';
import { useStore } from '../../../store/provider';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import EbikeAtlasTeaser from '../../Common/EbikeAtlasTeaser';

type NewsTeaserProps = TeaserProps & {
  data: NewsTeaserFragmentFragment;
};

const NewsTeaser: React.FC<NewsTeaserProps> = ({
  data,
  showCategory,
  ...rest
}) => {
  const store = useStore();
  const Icon = getIconForCategoryKey(data.category.key!);

  return (
    <Box key={data.id} width="100%">
      <EbikeAtlasTeaser
        type="news"
        text={data.teaserText || ''}
        image={data.teaserImage?.teaserLarge?.url}
        imageAlt={data.teaserImage?.imageAlt}
        imageTitle={data.teaserImage?.imageTitle}
        categoryKey={data.category.key}
        title={data.title}
        url={data.url}
        {...rest}
      />
    </Box>
  );
};

export default NewsTeaser;
