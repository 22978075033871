import { ParagraphNewsListFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import TeaserGrid from '../../Common/TeaserGrid';
import Wrapper from '../Wrapper';
import React from 'react';
import { useStore } from '../../../store/provider';
import NewsTeaser from '../../Node/News/NewsTeaser';

interface NewsListProps {
  data: ParagraphNewsListFragmentFragment;
}

const NewsList: React.FC<NewsListProps> = ({ data }) => {
  const store = useStore();

  return (
    <Wrapper className="ParagraphActivityList">
      <Container>
        <TeaserGrid
          columns={store.config.usesNewLayout ? 1 : 3}
          spacing={store.config.usesNewLayout ? 16 : 8}
        >
          {data.data!.items!.map((teaser) => (
            <NewsTeaser key={teaser!.id} data={teaser!} />
          ))}
        </TeaserGrid>
      </Container>
    </Wrapper>
  );
};

export default NewsList;
